export const PROJECT_NAME = 'beningo';
export const DISPLAY_NAME = 'Beningo';

export const isDevelopmentBuild = process.env.NODE_ENV === 'development';

export const getFrontendBaseUrl = () => {
    if (isDevelopmentBuild) {
        return 'localhost:3000';
    } else {
        return 'beningo.djvk.net';
    }
}

export enum BoardTypeId {
    Blitzer = '@boardType/blitzer',
    Benioff = '@boardType/benioff',
}

export interface BoardTypeDefinition {
    id: BoardTypeId;
    hostnames: Array<string>;
    typeParam: string;
    centerImageName: string;
}

export const boardTypeDefinitions: Record<BoardTypeId, BoardTypeDefinition> = {
    [BoardTypeId.Benioff]: {
        id: BoardTypeId.Benioff,
        hostnames: ['localhost', 'beningo.djvk.net', 'benibingo.com'],
        typeParam: 'benioff',
        centerImageName: '/benioff.png',
    },
    [BoardTypeId.Blitzer]: {
        id: BoardTypeId.Blitzer,
        hostnames: ['blitzerbingo.com'],
        typeParam: 'blitzer',
        centerImageName: '/blitzer_old.jpeg',
    },
}

export const getBoardType = (queryParams: URLSearchParams): BoardTypeId => {
    let typeParam = queryParams.get('type') || null;
    for (const rawId in boardTypeDefinitions) {
        const boardTypeId = rawId as BoardTypeId;
        if (boardTypeDefinitions[boardTypeId as BoardTypeId].typeParam === typeParam) {
            return boardTypeId as BoardTypeId;
        }
    }

    let hostname = window.location.hostname;
    for (const rawId in boardTypeDefinitions) {
        const boardTypeId = rawId as BoardTypeId;
        if (boardTypeDefinitions[boardTypeId as BoardTypeId].hostnames.includes(hostname)) {
            return boardTypeId as BoardTypeId;
        }
    }

    return BoardTypeId.Benioff;
};

export const getCenterImageName = (boardType: BoardTypeId) => {
    switch (boardType) {
        case BoardTypeId.Benioff:
            return '/benioff.png';
        case BoardTypeId.Blitzer:
            return '/blitzer_old.jpeg';
    }
};

export const getTitle = (boardType: BoardTypeId) => {
    switch (boardType) {
        case BoardTypeId.Benioff:
            return 'Beningo';
        case BoardTypeId.Blitzer:
            return 'Blitzer Bingo';
    }
};
