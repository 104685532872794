import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {modalSagas} from "../sagas/modalSagas";
import modalReducer from "../features/modal/modalSlice";
import boardReducer from "../features/board/boardSlice";

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

export const store = configureStore({
  reducer: {
    // connected-react-router typings are goofed https://github.com/reduxjs/redux-toolkit/issues/506
    router: connectRouter(history) as any,
    modalReducer,
    boardReducer,
  },
  middleware: middleware,
});

sagaMiddleware.run(modalSagas);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
