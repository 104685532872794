import React from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import {Board} from "./features/board/Board";

export enum AppRoute {
  Bingo = '/',
}

const App = () => (
  <Switch>
    <Route exact path={AppRoute.Bingo.valueOf()} component={Board}/>
    {/*<Route exact path="/counter" component={Counter}/>*/}
  </Switch>
);

export default App;
