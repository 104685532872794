import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import Confetti from 'react-confetti';
import {RootState} from "../../app/store";
import {init, toggleSquare} from "./boardSlice";
import {ModalContainer} from "../../lib/components/ModalContainer";
import styles from './Board.module.css';
import {BoardTypeId, getBoardType, getCenterImageName, getTitle} from "../../lib/constants";
import {log, LogLevels} from "../../lib/logger";
import {
    getDiagonalBingos,
    getHasBingoed,
    getHorizontalBingos,
    getVerticalBingos,
    mergeBingos
} from "../../lib/boardLogic";
import {getCenterCoordinates} from "../../lib/utils";

const renderCellContent = (
    type: BoardTypeId,
    x: number,
    y: number,
    cellWords: Array<Array<string>>,
    isSelected: boolean,
    center: Array<number>,
) => {
    let [centerX, centerY] = center;
    if (x === centerX && y === centerY) {
        // return <div className="free-space-container">
        return <img className={styles.freeSpace} src={getCenterImageName(type)} alt='center square'/>;
    } else if (isSelected) {
        return <div className={styles.selectedCell}>
            {renderCellText(x, y, cellWords)}
        </div>;
    } else {
        return renderCellText(x, y, cellWords);
    }
}

const renderCellText = (
    x: number,
    y: number,
    cellWords: Array<Array<string>>,
) => {
    return <p key={`cell-text-${x}-${y}`} className={`${styles.cellText} textCenter`}>{cellWords[y][x]}</p>;
}

const renderConfetti = (hasBingoed: boolean, size: number) => {
    if (!hasBingoed) {
        return null;
    }
    return <Confetti />;
}

export function renderSpinner(size = 'fa-lg') {
    return (
        <i className={`fa fa-spinner fa-spin ${size}`}/>
    );
}

export function Board() {
    const dispatch = useDispatch();

    const query = new URLSearchParams(useLocation().search)
    const type = getBoardType(query);

    // Selectors
    const wordPool = useSelector((state: RootState) => state.boardReducer.wordPool);
    const cellWords = useSelector((state: RootState) => state.boardReducer.cellWords);
    const boardSize = useSelector((state: RootState) => state.boardReducer.boardSize);
    const selectedCells = useSelector((state: RootState) => state.boardReducer.selectedCells);
    const {
        x: boardCellsX,
        y: boardCellsY,
    } = boardSize;

    // Callbacks
    const handleCellClick = useCallback((event) => {
        const target = event.currentTarget;
        if (!target) {
            log(`Cell clicked with null target`, LogLevels.Warn);
            return;
        }
        let attributes = target.attributes;
        let x = parseInt(attributes['data-x'].value, 10);
        let y = parseInt(attributes['data-y'].value, 10);
        let [centerX, centerY] = getCenterCoordinates(boardCellsX, boardCellsY);
        // Ignore clicks on the free space
        if (x === centerX && y === centerY) {
          return;
        }
        log(`Clicked x:${x}, y:${y}`);
        dispatch(toggleSquare({x, y}));
    }, [boardCellsX, boardCellsY]);

    // Effects
    useEffect(() => {
        if (wordPool.length === 0) {
            log(`Word pool empty, initializing...`);
            dispatch(init({
                boardType: type,
            }));
        }

        setTitleAndFavicon(type);

        // Don't need dispatch in dependencies, see
        // https://stackoverflow.com/questions/54930197/react-hooks-dispatch-action-from-useeffect
    }, [wordPool, type])

    // Local variables
    const horizontalBingos = getHorizontalBingos(selectedCells, boardSize, wordPool);
    const verticalBingos = getVerticalBingos(selectedCells, boardSize, wordPool);
    const diagonalBingos = getDiagonalBingos(selectedCells, boardSize, wordPool);
    const mergedBingos = mergeBingos(horizontalBingos, verticalBingos, diagonalBingos);
    const hasBingoed = getHasBingoed(mergedBingos);
    const center = getCenterCoordinates(boardCellsX, boardCellsY);

    // Render functions
    if (wordPool.length === 0) {
        return <div>
            {renderSpinner('fa-5x')}
        </div>;
    }

    return <div className="maxViewport">
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
        <div>
            <p className={styles.authorCredit}>By Dan van Kley. Dubious assistance by Rob Righter and "Eric" Berg.</p>
            <ModalContainer/>
            <div className={styles.boardContainer}>
                <div className={styles.headerContainer}>
                    {getHeaderElement(type)}
                </div>
                <div>
                    {renderConfetti(hasBingoed, 100)}
                    <div className={`${styles.gridContainer}`} style={{
                        gridTemplateColumns: `repeat(${boardCellsX}, 1fr)`,
                        gridTemplateRows: `repeat(${boardCellsY}, 1fr)`,
                    }}>
                        {[...Array(boardCellsY)].map((_, y) => {
                            return [...Array(boardCellsX)].map((_, x) => {
                                return <div key={`cell-container-${x}-${y}`}
                                            className={`${styles.cellContainer} ` +
                                            `${mergedBingos[y][x] ? `${styles.bingoed} ` : ' '}` +
                                            `${y === 0 ? `${styles.topRow} ` : ' '}` +
                                            `${y === boardCellsY - 1 ? `${styles.bottomRow} ` : ' '}` +
                                            `${x === 0 ? `${styles.firstColumn} ` : ' '}` +
                                            `${x === boardCellsX - 1 ? `${styles.lastColumn} ` : ' '}`}
                                            onClick={handleCellClick} style={{
                                    gridColumnStart: x + 1,
                                    gridRowStart: y + 1,
                                }} data-x={x} data-y={y}>
                                    {renderCellContent(type, x, y, cellWords, selectedCells[y][x], center)}
                                </div>;
                            });
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const getHeaderElement = (type: BoardTypeId) => {
    switch (type) {
        case BoardTypeId.Benioff:
            return <p className={styles.benioffHeader}>BENINGO</p>
        case BoardTypeId.Blitzer:
            return <>
                <p className={styles.blitzerHeader}>BLITZER</p>
                <p className={styles.blitzerHeader}>BINGO</p>
            </>
    }
};

const setTitleAndFavicon = (type: BoardTypeId) => {
    const existingTitle = document.getElementsByTagName('title');
    if (existingTitle.length > 0) {
        return;
    }
    const head = document.getElementById('root-head');
    if (!head) {
        throw new Error(`Failed to find head element`);
    }
    const title = document.createElement('title');
    const titleText = document.createTextNode(getTitle(type));
    title.appendChild(titleText);
    head.appendChild(title);

    switch (type) {
        case BoardTypeId.Benioff:
            // <link rel="icon" href="/favicon.ico" type="image/x-icon">
            const iconLink = createIconLink('image/x-icon', `favicon.ico`);
            head.appendChild(iconLink);
            break;
        case BoardTypeId.Blitzer:
            const sizes = ['16x16', '32x32', '96x96'];
            sizes.forEach((size) => {
                // <link id="favicon_16" rel="icon" type="image/png" href="blitzer_16x16.png" sizes="16x16">
                const iconLink = createIconLink('image/jpeg', `blitzer_old_${size}.jpeg`, size);
                head.appendChild(iconLink);
            });
            break;
    }
};

const createIconLink = (type: string, filename: string, size: string | null = null) => {
    const iconLink = document.createElement('link');
    iconLink.setAttribute('rel', 'icon');
    iconLink.setAttribute('type', type);
    iconLink.setAttribute('href', `/${filename}`);
    if (size) {
        iconLink.setAttribute('sizes', size);
    }
    return iconLink;
};
