import {BoardSize} from "../features/board/boardSlice";

export const hasInitialized = (
    selectedCells: Array<Array<boolean>>,
    boardSize: BoardSize,
    wordPool: Array<string>,
) => {
    return selectedCells.length > 0 &&
        Object.keys(boardSize).length === 2 &&
        wordPool.length > 0;
};

export const truthyMergeNestedArrays = (...grids: Array<Array<Array<boolean>>>): Array<Array<boolean>> => {
    if (!grids) {
        return [];
    }
    let out = grids.pop() as Array<Array<boolean>>;
    grids.forEach((grid) => {
        grid.forEach((row, y) => {
            row.forEach((cell, x) => {
                out[y][x] = out[y][x] || cell;
            });
        });
    });
    return out;
};

export const getHorizontalBingos = (
    selectedCells: Array<Array<boolean>>,
    boardSize: BoardSize,
    wordPool: Array<string>,
) => {
    if (!hasInitialized(selectedCells, boardSize, wordPool)) {
        return [];
    }
    let {
        x: boardCellsX,
        y: boardCellsY,
    } = boardSize;
    // Iterate over each row
    return [...Array(boardCellsY)].map((_, y) => {
        // For each row, check if all columns are selected
        let hasBingoed = (selectedCells[y].every((cellSelected) => cellSelected));
        return [...Array(boardCellsX)].map(() => hasBingoed);
    });
};

export const getVerticalBingos = (
    selectedCells: Array<Array<boolean>>,
    boardSize: BoardSize,
    wordPool: Array<string>,
) => {
    if (!hasInitialized(selectedCells, boardSize, wordPool)) {
        return [];
    }
    let {
        x: boardCellsX,
        y: boardCellsY,
    } = boardSize;
    let bingos = [...Array(boardCellsY)].map(() => {
        return [...Array(boardCellsX)].map(() => false);
    });
    // Iterate over each column
    [...Array(boardCellsX)].forEach((_, x) => {
        // For each column, check if all rows are selected
        if ([...Array(boardCellsY)].every((_, y) => selectedCells[y][x])) {
            [...Array(boardCellsY)].forEach((_, y) => bingos[y][x] = true);
        }
    });
    return bingos;
};

export const getDiagonalBingos = (
    selectedCells: Array<Array<boolean>>,
    boardSize: BoardSize,
    wordPool: Array<string>,
) => {
    if (!hasInitialized(selectedCells, boardSize, wordPool)) {
        return [];
    }
    let {
        x: boardCellsX,
        y: boardCellsY,
    } = boardSize;
    let bingos = [...Array(boardCellsY)].map(() => {
        return [...Array(boardCellsX)].map(() => false);
    });
    let leftDiagonal: Array<Array<number>> = [];
    let rightDiagonal: Array<Array<number>> = [];

    [...Array(boardCellsX)].forEach((_, x) => {
        let directY = x;
        let inverseY = boardCellsY - 1 - directY;
        leftDiagonal.push([x, directY]);
        rightDiagonal.push([x, inverseY]);
    });

    [leftDiagonal, rightDiagonal].forEach((diagonal) => {
        if (diagonal.every(([x, y]) => selectedCells[y][x])) {
            diagonal.forEach(([x, y]) => bingos[y][x] = true);
        }
    });
    return bingos;
};

export const mergeBingos = (
    horizontalBingos: Array<Array<boolean>>,
    verticalBingos: Array<Array<boolean>>,
    diagonalBingos: Array<Array<boolean>>,
): Array<Array<boolean>> => {
    return truthyMergeNestedArrays(horizontalBingos, verticalBingos, diagonalBingos);
};

export const getHasBingoed = (
    mergedBingos: Array<Array<boolean>>,
): boolean => {
    return mergedBingos.some((row) => {
        return row.some((cell) => cell);
    });
};
