import React, {ChangeEvent, useCallback, useState} from 'react';
import {InputResult, ModalCloseResult, ModalType, userClose, YesNoResult} from "../../features/modal/modalSlice";
import {Button, Form, Modal} from "react-bootstrap";
import {log, LogLevels} from "../logger";
import {selectText} from "../utils";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";

interface ResultTemplate {
  cancelled: boolean;
  confirmed: boolean;
}

const confirmedResultTemplate = {
  cancelled: false,
  confirmed: true,
};

const cancelledResultTemplate = {
  cancelled: true,
  confirmed: false,
};

export function ModalContainer() {
  const dispatch = useDispatch();
  const modalReducer = useSelector((state: RootState) => state.modalReducer, shallowEqual);
  const otherIsOpen = useSelector((state: RootState) => state.modalReducer.isOpen);
  log(`ModalContainer rerender`, LogLevels.Trace);
  const {
    isOpen,
    modalType,
    title,
    descriptionText,
    confirmButtonLabel,
    yesButtonLabel,
    noButtonLabel,
    outputText,
  } = modalReducer;

  const [textInput, setTextInput] = useState('');

  const parseResult = (template: ResultTemplate, modalType: ModalType): ModalCloseResult => {
    switch (modalType) {
      case ModalType.Confirm:
        return {
          ...template,
          type: ModalType.Confirm,
        };
      case ModalType.Output:
        return {
          ...template,
          type: ModalType.Output,
        };
      case ModalType.Input:
        return {
          ...template,
          type: ModalType.Input,
          input: textInput,
        } as InputResult;
      case ModalType.YesNo:
        return {
          ...template,
          type: ModalType.YesNo,
          no: false,
        } as YesNoResult;
    }
  };

  const handleConfirm = useCallback((arg?: string) => {
    log(`Confirm: ${arg}`);
    dispatch(userClose(parseResult(confirmedResultTemplate, modalType)));
    // Don't need dispatch in dependencies, see
    // https://stackoverflow.com/questions/54930197/react-hooks-dispatch-action-from-useeffect
  }, [parseResult, modalType]);

  const handleNo = useCallback(() => {
    log(`Cancel: no click`);
    let result: YesNoResult = {
      ...confirmedResultTemplate,
      type: ModalType.YesNo,
      confirmed: false,
      no: true,
    };

    dispatch(userClose(result));
  }, []);

  const handleCancel = useCallback((arg?: string) => {
    log(`Cancel: ${arg}`);
    dispatch(userClose(parseResult(cancelledResultTemplate, modalType)));
  }, [parseResult, modalType]);

  const renderBody = useCallback(() => {
    switch (modalType) {
      case ModalType.Confirm:
      case ModalType.YesNo:
        return <div>{descriptionText}</div>;
      case ModalType.Input:
        return <>
          <Form>
            <Form.Group controlId="modal.InputTextarea">
              <Form.Label>{descriptionText}</Form.Label>
              <Form.Control
                value={textInput || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  let rawVal = e.currentTarget.value;
                  setTextInput(rawVal || '');
                }}
                as="textarea"
                size="lg"
              />
            </Form.Group>
          </Form>
        </>;
      case ModalType.Output:
        return <>
          <div>
            <div>{descriptionText}</div>
            <Button
              onClick={() => {
                selectText('modal-output-element');
                document.execCommand("copy");
              }}
            >Copy to Clipboard</Button>
          </div>
          <pre><code id='modal-output-element'>{outputText}</code></pre>
        </>;
    }
  }, [modalType, descriptionText, outputText, textInput, setTextInput]);

  const renderButtons = useCallback(() => {
    switch (modalType) {
      case ModalType.Confirm:
      case ModalType.Input:
      case ModalType.Output:
        return <Button variant="primary" onClick={() => {
          handleConfirm('confirmClick')
        }}>{confirmButtonLabel}</Button>;
      case ModalType.YesNo:
        return <>
          <Button variant="primary" onClick={() => {
            handleConfirm('yesclick')
          }}>{yesButtonLabel}</Button>
          <Button variant="secondary" onClick={() => {
            handleNo()
          }}>{noButtonLabel}</Button>
        </>;
    }
  }, [modalType, confirmButtonLabel, yesButtonLabel, noButtonLabel, handleConfirm, handleNo]);

  log(`Modal is open: ${isOpen}`, LogLevels.Trace);
  log(`Other is open: ${otherIsOpen}`, LogLevels.Trace);

  return <Modal
    animation={false}
    show={isOpen}
    scrollable={true}
    centered={true}
    onHide={() => {
      handleCancel('modalHide')
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{renderBody()}</Modal.Body>
    <Modal.Footer>{renderButtons()}</Modal.Footer>
  </Modal>
}
