import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BoardTypeId, isDevelopmentBuild} from "../../lib/constants";
import {wordPool} from "../../lib/wordPool";
import {getCenterCoordinates} from "../../lib/utils";

// See modalSlice for the template comments for a reducer slice

export interface ToggleSquareAction {
  x: number;
  y: number;
}

export interface InitAction {
  boardType: BoardTypeId;
}

export interface BoardSize {
  x: number;
  y: number;
}

export interface BoardState {
  selectedCells: Array<Array<boolean>>;
  cellWords: Array<Array<string>>;
  boardSize: BoardSize;
  wordPool: Array<string>;
}

const initialState: BoardState = {
  // rows [columns]
  selectedCells: [],
  cellWords: [],
  boardSize: {
    x: 5,
    y: 5
  },
  wordPool: [],
};

export const boardSlice = createSlice({
  name: 'bingo',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<InitAction>) => {
      let boardType = action.payload.boardType;
      let words = wordPool[boardType];
      state.wordPool = words;
      state.selectedCells = clearSelectedCells(state.boardSize.x, state.boardSize.y);
      state.cellWords = distributeWords(state.boardSize.x, state.boardSize.y, words);
    },
    toggleSquare: (state, action: PayloadAction<ToggleSquareAction>) => {
      let existingValue = state.selectedCells[action.payload.y][action.payload.x];
      state.selectedCells = immutableUpdateObjectInArray(state.selectedCells, action.payload.y,
          immutableUpdateObjectInArray(state.selectedCells[action.payload.y], action.payload.x, !existingValue));
    },
  },
});

function clearSelectedCells(xCells: number, yCells: number): Array<Array<boolean>> {
  let cells = [...Array(yCells)].map((y) => {
    return [...Array(xCells)].map((x) => {
      return false;
    });
  });
  let [centerX, centerY] = getCenterCoordinates(xCells, yCells);
  cells[centerY][centerX] = true;
  return cells;
}

function distributeWords(xCells: number, yCells: number, wordPool: Array<string>): Array<Array<string>> {
  // Copy the word pool
  let words = wordPool.slice(0);
  return [...Array(yCells)].map((y) => {
    return [...Array(xCells)].map((x) => {
      // Find a random word
      let wordIndex = Math.round(Math.random() * (words.length - 1));

      // Remove the word from the candidate word list and return it
      return words.splice(wordIndex, 1)[0];
    });
  });
}

function immutableUpdateObjectInArray(array: Array<any>, index: number, newValue: any) {
  return array.map((value, i) => {
    if(i !== index) {
      // This isn't the droid we are looking for
      return value;
    }

    // Otherwise, this is the one we want - return an updated value
    return newValue;
  });
}

export const {
  init,
  toggleSquare
} = boardSlice.actions;

export default boardSlice.reducer;
