import {BoardTypeId} from "./constants";

export const wordPool: Record<BoardTypeId, Array<string>> = {
    [BoardTypeId.Benioff]: [
        'Trust',
        'Impact',
        'Synergy',
        'Transform',
        '__est __ in the industry',
        'Mobile',
        'Aloha',
        'Journey',
        'Lightning',
        'Apps',
        'Enable',
        'Innovation',
        'Platform',
        'Diversity',
        'Billion',
        'Dreamforce',
        'Closing Business',
        'Run Your Business From Your Phone',
        'Accelerate',
        'Component',
        'Packaging',
        'Tremendous',
        'San Francisco',
        'Ecosystem',
        'Cloud 3x in 10 seconds',
        'Mahalo',
        'Alex says something unintelligible',
        'Opportunity',
        'Email',
        'Pipeline',
        'Interactive',
        'Delivering Value',
        'Collaborate',
        'Analytics',
        'Process',
        'Integrated',
        'Real-Time',
        'Data',
        'Fourth Industrial Revolution',
        'Quality',
        'Ohana',
        'Incredible',
        'Completely unrelatable story',
        'Salesforce Tower',
        'Trail.*',
        'Einstein',
        'Forbes',
        'Fastest __ing business',
        'Parker',
        'Vision',
        'Engagement',
        'Leveraged',
        'Customer Success',
        'Keynote',
        'Inspire',
        'Innovation Economy',
        'Here to thank you',
        'Mindfulness',
        'Mindset',
        'Beginner\'s Mind',
        'No Software',
        'Enterprise',
        'Dogfooding',
        'Best of Breed',
        'Alignment',
        'Out of the Box',
        'Strategic',
        'The Story We Tell',
        'Really Good Question',
        'Top of Mind',
        'Laser Focused',
        'Salesforce Economy',
        'Supercharged',
        '360 View',
        'Metallica',
        'Never been done before',
        'Asks for applause',
        'Digital Transformation',
        'Mission Critical',
        'Hyperforce',
        'Slack',
        'Single Source of Truth',
        'Genie',
        '>10 minutes late',
        'Inappropriate COVID joke',
        'Productivity',
        'Hired too much',
        'Transparency',
        'Talking about his feelings too much',
        'Weird death metaphor',
    ],
    [BoardTypeId.Blitzer]: [
        'Bald',
        'Judo',
        'Cincinnati',
        'Pardot',
        'Atlanta',
        'Japan',
        'B2B',
        'ExactTarget',
        'Acquisition',
        'Latvia(n)',
        '2007',
        'Co-founder',
        'Ohio',
        'Sales Cloud',
        'GM',
        'Bootstrapped',
        'Culture',
        'Self-Starting',
        'Core Values',
        'Vertical vs Horizontal',
        'Journey Builder',
        'Martech',
        'The Martech X Thousand Eyechart',
        'Adam Wearing that Beige Sweater with the Huge Collar',
        'Core',
        'TAM',
        'Data Lake',
        'Self-Deprecating Joke',
        'Hyperforce',
        'Slack',
        'Future of Work',
        "Digital First",
        "CDP",
        "Orchestration",
        "Evergage",
        "Geo",
        "Single Source of Truth",
        'Online',
        'Digital Experience',
        'Chief Digital Officer',
        'CMO',
        'Reimagine',
        'Flexible',
        'Scalable',
        'Ekta Chopra',
        'Innovation',
        'Customer 360 Audiences',
        'SSOT',
        'Pandemic',
        'OMS',
        'From Anywhere',
    ],
}
